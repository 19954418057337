import React, { CSSProperties, FC } from 'react';
import { Button } from 'icas.core.reactcomponents';
import Dropzone from 'react-dropzone';

import AddedFile from '../assets/addedFile.png';
import File from '../assets/file.png';
import { ReactComponent as RemoveIcon } from '../assets/remove.svg';

import { ImageIcon } from './ImageIcon';

type UploadFunction = <T>(files: T[]) => void;

type FileUploadProps = {
	upload: UploadFunction;
	remove: () => void;
	transcripts: File[];
};

export const FileUpload: FC<FileUploadProps> = ({
	upload,
	remove,
	transcripts,
}) => {
	const style: CSSProperties = {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: 'calc(100% + 0.15em)',
		paddingTop: '1em',
		marginTop: '1em',
		paddingBottom: '1em',
		marginBottom: '1em',
		backgroundColor: '#E8F8FB',
		outline: 'none',
	};

	const removeIconStyle = {
		height: '2em',
		cursor: 'pointer',
		marginLeft: 'auto',
		paddingRight: '1em',
		border: 'none',
		backgroundColor: 'transparent',
	};

	if (!transcripts || (transcripts && !transcripts.length)) {
		return (
			<div className="component-container">
				<Dropzone
					onDrop={acceptedFiles => upload(acceptedFiles)}
					maxFiles={1}
					maxSize={5000000}
					accept={['.pdf', 'image/jpeg', 'image/png']}
				>
					{({ getRootProps, getInputProps }) => (
						<section>
							<div {...getRootProps({ style })}>
								<input {...getInputProps()} />
								<p>Please upload your transcript here</p>
								<em>Only one file upload allowed. Max size 5mb</em>
								<em>Only pdf, jpeg and png file types accepted</em>
								<ImageIcon image={File} alt="" height="4em" />
							</div>
						</section>
					)}
				</Dropzone>
			</div>
		);
	}
	const fileNames = transcripts.map(transcript => (
		<ul key={transcript.name} style={{ margin: 'auto' }}>
			<ImageIcon image={AddedFile} alt="" height="4em" />
			{transcript.name}
		</ul>
	));
	return (
		<div className="component-container">
			<Dropzone disabled>
				{({ getRootProps, getInputProps }) => (
					<section>
						<div {...getRootProps({ style })}>
							<input {...getInputProps()} />
							<Button
								id="removeDoc"
								style={removeIconStyle}
								onClick={remove}
								text="remove document"
								mode="icon"
								icon={<RemoveIcon style={{ fill: 'red' }} />}
							/>
							{fileNames}
						</div>
					</section>
				)}
			</Dropzone>
		</div>
	);
};
