import React, { FC, useContext } from 'react';
import {
	AuthContext,
	Layout,
	LoadingLottie,
	LoadingStatus,
	PrivateRoute,
} from 'icas.core.reactcomponents';
import { Route, Routes } from 'react-router-dom';

import { ExemptionsContainer } from './containers/ExemptionsContainer';

import './custom.css';

export const App: FC = (): JSX.Element => {
	const { currentLoading } = useContext(AuthContext);

	const loginPath =
		'/authentication/login?returnUrl=' +
		encodeURIComponent(window.location.toString());

	let screen = (
		<LoadingLottie
			style={{
				height: '15em',
				width: '15em',
				margin: 'auto',
				marginBottom: '15em',
				paddingTop: '5em',
			}}
		/>
	);

	if (
		currentLoading === LoadingStatus.EndedWithError ||
		currentLoading === LoadingStatus.LoadedSuccessfully
	) {
		screen = (
			<Routes>
				<Route
					path="/"
					element={
						<PrivateRoute
							loginPath={loginPath}
							redirectPath="'https://www.icas.com'"
						>
							<ExemptionsContainer />
						</PrivateRoute>
					}
				/>
			</Routes>
		);
	}

	return (
		<Layout>
			<div id="app-container">
				<div id="title-container">
					<h1 id="app-title">Student Exemptions</h1>
				</div>
				{screen}
			</div>
		</Layout>
	);
};
