import React, { FC, useState } from 'react';
import { Button, Select } from 'icas.core.reactcomponents';

import { returnDegreeOptions } from '../utils/degreeOptions';

import { MortarImage } from './MortarImage';

import './ComponentStyle.css';
import './DropdownStyle.css';

type HandleSelectFunction = (value: string) => void;

type DropdownMenuProps = {
	uniCode: number;
	handleSelect: HandleSelectFunction;
};

const ButtonStyle = {
	marginLeft: '1em',
	width: '8em',
	height: '40px',
	marginTop: '0.5em',
};

export const DropdownMenu: FC<DropdownMenuProps> = ({
	uniCode,
	handleSelect,
}) => {
	const [value, setValue] = useState('');

	const handleSubmit = () => {
		handleSelect(value);
	};

	const degreeOptions = returnDegreeOptions(uniCode);

	if (!degreeOptions.length) {
		return null;
	}

	const handleChange = (_: string, value: string | number) =>
		setValue(value.toString());

	return (
		<div className="component-container student-degree-container">
			<div className="degree-text">
				<h3>Please select your degree:</h3>
				<div className="select-and-button">
					<Select
						showLabel={false}
						id={'degree-select'}
						labelText={'Please select your degree'}
						handleChange={handleChange}
						selectedOption={value}
						options={degreeOptions}
					/>

					<Button
						id="degree-button"
						onClick={handleSubmit}
						text="Select"
						style={ButtonStyle}
					/>
				</div>
			</div>
			<div className="degree-image">
				<MortarImage />
			</div>
		</div>
	);
};
