import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { App } from './App';
import { AuthProvider } from 'icas.core.reactcomponents';
import registerServiceWorker from './registerServiceWorker';

const rootElement = document.getElementById('root');

ReactDOM.render(
	<Router>
		<AuthProvider>
			<App />
		</AuthProvider>
	</Router>,
	rootElement
);

registerServiceWorker();
