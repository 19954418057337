import { useCallback, useContext, useEffect, useState } from 'react';
import {
	AuthContext,
	FetchHelper,
	FetchResponse,
	LoadingStatus,
} from 'icas.core.reactcomponents';

import { StudentInfo } from '../types/StudentInfo';
import { UniInfo } from '../types/UniInfo';
import { isUnauthCode } from '../utils/responseCodes';
import { LeicesterValue, RguValue, universities } from '../utils/universities';

type UseFetchDataProps = {
	isNotICAEW: boolean;
	setIsAccreditedUni: (isAccredited: boolean) => void;
	setLoadingStatus: (loadingStatus: LoadingStatus) => void;
	pageLoaded: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataToSetFunction = (data: any) => void;

export const useFetchData = ({
	isNotICAEW,
	setIsAccreditedUni,
	setLoadingStatus,
	pageLoaded,
}: UseFetchDataProps) => {
	const [universityInfo, setUniversityInfo] = useState<UniInfo>();
	const [studentInfo, setStudentInfo] = useState<StudentInfo>();
	//leicester and rgu are the only unis with breakdown by degree therefore it they are 'special cased' in the code
	const [selectedDegree, setSelectedDegree] = useState<boolean>(false);
	const [universityInfoArray, setUniversityInfoArray] = useState<UniInfo[]>();
	const { userId, handleUnauth } = useContext(AuthContext);

	const needsDegreeInfo =
		(studentInfo?.icas_universityattendedcode === LeicesterValue ||
			studentInfo?.icas_universityattendedcode === RguValue) ??
		false;

	const degreeSelectionOutstanding = needsDegreeInfo && !selectedDegree;
	//#region Request Handlers

	const handleResponse = useCallback(
		async (fetchResponse: FetchResponse, dataToSet: DataToSetFunction) => {
			if (fetchResponse.isOK) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const data: any = fetchResponse.body;
				dataToSet(data);
				setLoadingStatus(LoadingStatus.LoadedSuccessfully);
			} else {
				if (isUnauthCode(fetchResponse.statusCode)) {
					setLoadingStatus(LoadingStatus.EndedWithError);
					return handleUnauth();
				}
			}
		},
		[handleUnauth, setLoadingStatus]
	);

	const getContactData = useCallback(async () => {
		const fetchUrl = `/exemptions/contact/${userId}`;
		const fetchResponse = await FetchHelper.get(
			fetchUrl.toString(),
			handleUnauth
		);
		await handleResponse(fetchResponse, setStudentInfo);
	}, [handleResponse, handleUnauth, userId]);

	const getModuleData = useCallback(async () => {
		if (!isNotICAEW) {
			const uniData = await FetchHelper.get(
				`/exemptions/uni/ICAEW`,
				handleUnauth,
				true
			);
			return await handleResponse(uniData, setUniversityInfo);
		}
		const uniIsAccredited = universities.find(
			uni => uni.value === studentInfo?.icas_universityattendedcode
		);
		if (uniIsAccredited && needsDegreeInfo) {
			setIsAccreditedUni(true);
			const uniData = await FetchHelper.get(
				`/exemptions/uni/${studentInfo?.icas_universityattendedcode}`,
				handleUnauth,
				true
			);
			return await handleResponse(uniData, setUniversityInfoArray);
		}
		if (uniIsAccredited && !needsDegreeInfo) {
			setIsAccreditedUni(true);
			const uniData = await FetchHelper.get(
				`/exemptions/uni/${studentInfo?.icas_universityattendedcode}`,
				handleUnauth,
				true
			);
			return await handleResponse(uniData, setUniversityInfo);
		}
		setIsAccreditedUni(false);
		setLoadingStatus(LoadingStatus.LoadedSuccessfully);
	}, [
		isNotICAEW,
		needsDegreeInfo,
		handleUnauth,
		handleResponse,
		studentInfo?.icas_universityattendedcode,
		setIsAccreditedUni,
		setLoadingStatus,
	]);

	const handleSelectDegree = (degree: string) => {
		setSelectedDegree(true);
		const selectedDegree: UniInfo[] = [];
		universityInfoArray?.forEach(option => {
			const degreeToCompare = option.degrees.toString();
			if (degreeToCompare === degree) {
				selectedDegree.push(option);
			}
		});

		if (selectedDegree.length > 0) {
			const leicesterSelection = selectedDegree[0];
			setUniversityInfo(leicesterSelection);
		}
	};

	useEffect(() => {
		if (pageLoaded && !studentInfo) {
			getContactData();
		}
	}, [
		userId,
		getContactData,
		pageLoaded,
		studentInfo,
		setLoadingStatus,
		getModuleData,
	]);

	useEffect(() => {
		if (studentInfo && !universityInfo) {
			getModuleData();
		}
	}, [studentInfo, universityInfo, getModuleData]);

	return {
		studentInfo,
		universityInfo,
		handleSelectDegree,
		selectedDegree,
		needsDegreeInfo,
		degreeSelectionOutstanding,
	};
};
