import React from 'react';
import { Checkbox } from 'icas.core.reactcomponents';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { HandleFunction } from '../hooks/useRules';
import { renderRuleLabels } from '../utils/Formatting';

type RuleCheckboxProps = {
	id: string;
	handleCheckbox: HandleFunction;
	checked: boolean;
};

type RulesProps = {
	modulesArray: string[] | string[][];
	subject: string;
	children?: React.ReactNode;
	type: 'all' | 'or' | 'or both';
	spanName: string;
};

export const RuleCheckbox = ({
	id,
	handleCheckbox,
	checked,
}: RuleCheckboxProps) => (
	<span className="checkbox-span">
		<Checkbox
			id={`${id}-checkbox`}
			handleChange={handleCheckbox}
			checked={checked}
			label={'Confirmation'}
			showLabel={false}
			aria-labelledby={id}
		/>
	</span>
);

export const Rules = ({
	modulesArray,
	subject,
	children,
	type,
	spanName = `${subject}-all-module`,
}: RulesProps) => {
	const modulesLength = modulesArray.length;
	const allModules = modulesArray.map((module: unknown) => {
		if ((module as string).startsWith('For those graduating')) {
			return <p key={module as string}>{module}</p>;
		}
		return (
			<li key={module as string}>
				<FontAwesomeIcon color="#22b9da" icon={faArrowRight} /> {module}
			</li>
		);
	});

	const label = renderRuleLabels(type, modulesLength);

	return (
		<div key={'all'} className="module-info">
			<span className="info-span" id={spanName}>
				<h4 style={{ fontWeight: '700', fontSize: '1rem' }}>{label}</h4>
				{allModules}
			</span>
			{children}
		</div>
	);
};
